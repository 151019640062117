export type * from "./api";

//
import { createApiInstance } from "./api";
import { ServicesOptions, ServicesInstance } from "../models";
import { getBaseHelpers, rootProps } from "@tc/base/helpers";
import { getLoglevelExpanded } from "@tc/base/loglevel";
import { theme } from "@tc/dom/helpers";

export function initializeServices<T extends rootProps.RootProps>(options: ServicesOptions<T>): ServicesInstance<T> {
  return {
    helper: getBaseHelpers(options.helper),
    api: createApiInstance(options.api),
    log: getLoglevelExpanded(options.log),
    theme: theme.initRootProps<T>(options.theme),
  };
}
